import React, { Component } from 'react';
import moment from 'moment/min/moment-with-locales';
import API from "../API";

class News extends Component {
  constructor() {
    super();

    this.state = {
      news: [],
    };
  }

  async componentDidMount() {
    const news = await this.getNews();

    this.setState({
      news: news
    });
  };

  getNews = async () => {
    const config = {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'X-Request-Source': 'admin',
      },
    };

    const response = await API.get('wp-json/eupit/v1/assessor-news', config);

    return response.data.map(post => ({
      id: post.ID,
      title: post.post_title,
      date: post.post_modified,
      content: post.post_content,
      clicked: false,
    }));
  };

  handleOldArticleClicked = (id) => {
    this.setState({
      news: this.state.news.map(article => {
        if (article.id === id) {
          return {
            ...article,
            clicked: !article.clicked,
          };
        } else {
          return article;
        }
      })
    });
  };

  render() {
    const { news } = this.state;

    if (!news || news.length === 0) {
      return null;
    }

    const latestArticle = news[0];

    const oldNewsJSX = news.slice(1).map(article => (
      <div key={article.id}>
        <h3 className="black hoverable" onClick={() => this.handleOldArticleClicked(article.id)}>
          <p>{article.title}<span className="article-date"> ({moment(article.date).format('LL')})</span></p>
        </h3>
        {article.clicked && (
          <p className="article-content px-1" dangerouslySetInnerHTML={{ __html: article.content }}></p>
        )}
      </div>
    ));

    return (
      <div className="news">
        <p className="section-title">Obavijesti za ocjenitelje</p>
        <h2>{latestArticle.title}</h2>
        <p className="article-content" dangerouslySetInnerHTML={{ __html: latestArticle.content }}></p>
        <h2>Ostale vijesti</h2>
        <div className="old-articles">{oldNewsJSX}</div>
      </div>
    );
  }
}

export default News;