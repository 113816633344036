import React, { Component } from 'react';
import API from '../API';

class AddressBook extends Component {
  constructor() {
    super();

    this.ACCOUNTS_PER_PAGE = 5;
    this.PAGE_NUMBERS_PER_PAGE = 7;
    this.state = {
      pages: {},
      selectedPage: 1,
      searchTerm: '',
      sort: 1,
    };
  }

  async componentDidMount() {
    const users = await this.getUsers();
    this.users = this.filterUsersByRole(users);

    this.setState({
      pages: this.paginateUsers(this.users),
    });
  }

  filterUsersByRole = (users) => {
    const userString = window.localStorage.getItem('authorizedUser');

    const loggedUser = JSON.parse(userString);
    return users; //TODO: potvrdili da je obo u redu

    if (loggedUser.roleList.indexOf('administrator') !== -1) {
      return users;
    }

    //TODO: HAA i ocjenitelj ne vidi kontakte

    return users.filter(user => {
      for (let i = 0; i < loggedUser.roleList.length; i++) {
        if (user.roleList && user.roleList.indexOf(loggedUser.roleList[i]) !== -1) {
          return true;
        }
      }

      return false;
    });
  };

  getUsers = async () => {
    const config = {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'X-Request-Source': 'admin',
      },
    };

    const response = await API.get('wp-json/eupit/v1/assessors', config);
    return this.sortUsers(response.data.map(user => ({
      address: user.meta.address,
      city: user.meta.city,
      id: user.id,
      email: user.user_email,
      firstName: user.meta.first_name,
      lastName: user.meta.last_name,
      phoneNumber: user.meta.phoneNumber,
      roleList: user.roleList,
      zip: user.meta.zipcode,
    })), 1);
  };

  paginateUsers = (users) => {
    const pages = {};
    let page = -1;

    for (let i = 0; i < users.length; i++) {
      if (i % this.ACCOUNTS_PER_PAGE === 0) {
        page++;
        pages[page] = [];
      }

      pages[page].push(users[i]);
    }

    return pages;
  };

  sortUsers = (users, sort) => {
    return users.sort((a, b) => {
      if(!a.lastName || !b.lastName) {
        return 0;
      }
      return a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase()) * sort;
    });
  };

  filterUsers = (users, searchTerm) => {
    return users.filter(user => {
      return user.lastName && user.lastName.toLowerCase().startsWith(searchTerm.toLowerCase())
    });
  };

  handleSortClicked = () => {
    const { searchTerm, sort } = this.state;

    const filteredUsers = this.filterUsers(this.users, searchTerm);
    const sortedUsers = this.sortUsers(filteredUsers, -sort);
    const paginatedUsers = this.paginateUsers(sortedUsers);

    this.setState({
      pages: paginatedUsers,
      sort: -sort,
    });
  };

  handleSearchUpdate = (event) => {
    const { sort } = this.state;
    const searchTerm = event.target.value;

    const filteredUsers = this.filterUsers(this.users, searchTerm);
    const sortedUsers = this.sortUsers(filteredUsers, sort);
    const paginatedUsers = this.paginateUsers(sortedUsers);

    this.setState({
      pages: paginatedUsers,
      searchTerm,
      selectedPage: 1,
    });
  };

  handlePageClicked = (pageNumber) => {
    this.setState({
      selectedPage:  pageNumber,
    });
  };

  generatePaginationJSX = (pages, selectedPage) => {
    // determine maximum page number in pagination bar
    const maxPageNumber = Object.keys(pages).length;
    let paginationJSX = [];

    const isSelectedPageInTheBeginning = selectedPage <= this.PAGE_NUMBERS_PER_PAGE - 2;
    const isSelectedPageInTheEnd = selectedPage > (maxPageNumber - this.PAGE_NUMBERS_PER_PAGE + 2);
    const isSelectedPageInTheMiddle = !isSelectedPageInTheBeginning && !isSelectedPageInTheEnd;

    for (let i = 1; i <= maxPageNumber; i++) {
      const isFirstPage = i === 1;
      const isLastPage = i === maxPageNumber;

      const renderCondition = (
        isFirstPage
        || isLastPage
        || (isSelectedPageInTheBeginning && i <= this.PAGE_NUMBERS_PER_PAGE - 1)
        || (isSelectedPageInTheEnd && i >= (maxPageNumber - this.PAGE_NUMBERS_PER_PAGE + 2))
        || (isSelectedPageInTheMiddle && (i >= selectedPage - 2 && i <= selectedPage + 2))
      );
      const renderEllipsis = (
        (isSelectedPageInTheBeginning && i === (this.PAGE_NUMBERS_PER_PAGE - 1))
        || (isSelectedPageInTheEnd && i === (maxPageNumber - this.PAGE_NUMBERS_PER_PAGE + 2))
        || (isSelectedPageInTheMiddle && (i === selectedPage - 2 || i === selectedPage + 2))
      );

      if (renderCondition) {
        paginationJSX.push(<button key={i} className={i === selectedPage ? 'hoverable selected' : 'hoverable'} onClick={() => this.handlePageClicked(i)}>{renderEllipsis ? '…' : i}</button>);
      }
    }

    return paginationJSX;
  };

  generateUsersJSX = (pages, selectedPage) => {
    let usersJSX;

    if (pages[selectedPage - 1]) {
      usersJSX = pages[selectedPage - 1].map(user => (
        <div key={user.id} className="list-card">
          <div className="card-header">
            <p className="card-header-title">{user.firstName} {user.lastName}</p>
          </div>
          <div className="card-data">
            <p className="card-data-property-name">adresa</p>
            <p className="card-data-property-value">{user.address}</p>
            <p className="card-data-property-name">mjesto</p>
            <p className="card-data-property-value">{user.city}</p>
            <p className="card-data-property-name">zip</p>
            <p className="card-data-property-value">{user.zip}</p>
            <p className="card-data-property-name">telefon</p>
            <p className="card-data-property-value">{user.phoneNumber}</p>
            <p className="card-data-property-name">email</p>
            <p className="card-data-property-value">{user.email}</p>
          </div>
        </div>
      ));
    }

    return usersJSX;
  };

  render() {
    const { pages, selectedPage } = this.state;

    const paginationJSX = this.generatePaginationJSX(pages, selectedPage);
    const usersJSX = this.generateUsersJSX(pages, selectedPage);

    return (
      <div className="address-book">
        <p className="section-title">Adresar korisnika</p>
        <p className="text-uppercase">Ukupno korisnika: <strong>{this.users ? this.users.length : 0}</strong></p>
        <div className="search-bar">Pretraga po prezimenu: <input type="text" name="search" placeholder="Prezime" value={this.state.searchTerm} onChange={this.handleSearchUpdate}/></div>
        <div className="sort-bar"><button className="hoverable" onClick={this.handleSortClicked}>{this.state.sort === -1 ? 'Sortiraj po prezimenu A-Z' : 'Sortiraj po prezimenu Z-A'}</button></div>
        <div className="users-list">{usersJSX}</div>
        <div className="pagination-bar">{paginationJSX}</div>
      </div>
    );
  }
}

export default AddressBook;