import { withFormik } from 'formik';
import * as Yup from 'yup';
import { RegisterForm } from "./RegisterForm";
import API from "../../API";

export const Register = withFormik({
  mapPropsToValues: () => ({
    address: '',
    city: '',
    cityDialCode: '',
    countryDialCode: '',
    email: '',
    name: '',
    phoneNumber: '',
    postal: '',
    surname: '',
    userGroup: 'assessor',
    username: '',
  }),

  validationSchema: Yup.object().shape({
    address: Yup.string().required('Ulica i kućni broj su obavezni').min(5, 'Ulica i kućni broj moraju imati minimalno 5 znakova'),
    // city: Yup.string().required('Mjesto je obavezno'),
    cityDialCode: Yup.string().matches(/\d{1,5}/, 'Dozvoljeni su samo brojevi'),
    countryDialCode: Yup.string().matches(/\d{1,5}/, 'Dozvoljeni su samo brojevi'),
    email: Yup.string().email('Neispravna email adresa').required('Email adresa je obavezna'),
    name: Yup.string().required('Ime je obavezno'),
    phoneNumber: Yup.string().required('Broj telefona/mobitela je obavezan').min(6, 'Broj telefona/mobitela mora imati minimalno 6 znakova').matches(/\d{6,12}/, 'Dozvoljeni su samo brojevi'),
    postal: Yup.string().required('Poštanski broj je obavezan').min(5, 'Poštanski broj mora imati minimalno 5 znakova').matches(/\d{5,}/, 'Dozvoljeni su samo brojevi'),
    surname: Yup.string().required('Prezime je obavezno'),
    userGroup: Yup.string().required('Morate izabrati korisničku grupu'),
    username: Yup.string().required('Korisničko ime je obavezno').min(4, 'Korisničko ime mora imati minimalno 4 znaka'),
  }),

  handleSubmit: async (values, { props, setSubmitting }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const data = {
      "address": values.address,
      "city": values.city,
      "email": values.email,
      "name": values.name,
      "phoneNumber": values.countryDialCode + values.cityDialCode + values.phoneNumber,
      "postal": values.postal,
      "surname": values.surname,
      "userGroup": values.userGroup,
      "username": values.username,
    };

    try {
      const response = await API.post('wp-json/eupit/v1/register', data, config);

      alert('Zahtjev za registraciju je uspješno poslan.');
    } catch (error) {
      if (error.response.status === 409) {
        alert('Korisnik s navedenim korisničkim imenom već postoji');
      } else {
        console.error(error);
      }
    } finally {
      setSubmitting(false);
    }
  },

  displayName: 'Register',
})(RegisterForm);
