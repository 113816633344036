import React, {Component} from 'react';
import { CitySelector } from "../../components/CitySelector";
import { AcreditationSchemaSelector } from "./AcreditationSchemaSelector";
import { AcreditationAreaSelector } from "./AcreditationAreaSelector";

export class InquiryForm extends Component {
  handleOIBChange = (e, handleChange) => {
    const value = e.target.value;

    if (value.length <= 11 && (+value || value === '')) {
      handleChange(e);
    }
  };

  handleDialCodeChange = (e, handleChange) => {
    const value = e.target.value;

    if (value.length <= 3 && (!isNaN(value) || value === '')) {
      handleChange(e);
    }
  };

  handleNumberChange = (e, handleChange) => {
    const value = e.target.value;

    if (value.length <= 12 && (!isNaN(value) || value === '')) {
      handleChange(e);
    }
  };

  handleAcreditationSchemaSelectorChange = (e, schemas, handleChange, handleAcreditationSchemaChange) => {
    const selectElement = e.target;
    const selectedId = selectElement.options[selectElement.selectedIndex].id;

    const selectedSchema = schemas.find(schema => schema.id === selectedId);

    handleChange(e);
    handleAcreditationSchemaChange(selectedSchema);
  };

  handleAcreditationAreaChange = (e, values, setFieldValue) => {
    if (e.target.checked) {
      setFieldValue('acreditationArea', [ ...values.acreditationArea, e.target.value ]);
    } else {
      setFieldValue('acreditationArea', values.acreditationArea.filter(area => area !== e.target.value));
    }
  };

  render() {
    const {
      areas,
      cities,
      errors,
      handleAcreditationSchemaChange,
      handleBlur,
      handleChange,
      handleSubmit,
      schemas,
      setFieldValue,
      touched,
      values,
    } = this.props;


    let unit = null;

      if(values.isOrganisationalUnit) {
        unit = (
          <>
            <p className="form-element-label">Naziv organizacijske jedinice</p>
            <div className="form-element">
                <input
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.organisationalUnitName}
                    name="organisationalUnitName"
                    maxLength={50}
                    placeholder="Naziv organizacijske jedinice"
                />
            {errors.organisationalUnitName && touched.organisationalUnitName && <div className="form-element-feedback">{errors.organisationalUnitName}</div>}
            </div>
            <p className="form-element-label">Adresa organizacijske jedinice</p>
            <div className="form-element">
                <input
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.organisationalUnitAddress}
                    name="organisationalUnitAddress"
                    maxLength={50}
                    placeholder="Adresa organizacijske jedinice"
                />
                {errors.organisationalUnitAddress && touched.organisationalUnitAddress && <div className="form-element-feedback">{errors.organisationalUnitAddress}</div>}
            </div>
            <p className="form-element-label">Grad organizacijske jedinice u RH</p>
            <div className="form-element">
                <CitySelector
                    cities={cities}
                    name='organisationalUnitCity'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.organisationalUnitCity}
                    disabled={!cities || cities.length === 0}
                />
                {errors.organisationalUnitCity && touched.organisationalUnitCity && <div className="form-element-feedback">{errors.organisationalUnitCity}</div>}
            </div>
          </>
        )
      }

    return (
      <form onSubmit={handleSubmit}>
        <div className="eupit-form-elements">
            <p className="form-element-label">Naziv organizacije i pravni status (npr. d.d. ili d.o.o.)</p>
            <div className="form-element">
              <input
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.organisation}
                name="organisation"
                maxLength={250}
                placeholder="Naziv organizacije i pravni status"
              />
              {errors.organisation && touched.organisation &&
              <div className="form-element-feedback">{errors.organisation}</div>}
            </div>
            <p className="form-element-label">OIB</p>
            <div className="form-element">
                <input
                  type="number"
                  onChange={(e) => this.handleOIBChange(e, handleChange)}
                  onBlur={handleBlur}
                  value={values.OIB}
                  name="OIB"
                  placeholder="OIB"
                />
                {errors.OIB && touched.OIB && <div className="form-element-feedback">{errors.OIB}</div>}
            </div>
            <p className="form-element-label">Ulica i kućni broj</p>
            <div className="form-element">
                <input
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address}
                  name="address"
                  maxLength={50}
                  placeholder="Ulica i kućni broj"
                />
                {errors.address && touched.address && <div className="form-element-feedback">{errors.address}</div>}
            </div>
            <p className="form-element-label">Grad</p>
            <div className="form-element">
                <CitySelector
                  cities={cities}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.city}
                  name='city'
                  disabled={!cities || cities.length === 0}
                />
                {errors.city && touched.city && <div className="form-element-feedback">{errors.city}</div>}
            </div>

            <div className="form-element">
              <label>
                <input
                  type="checkbox"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="isOrganisationalUnit"
                  name="isOrganisationalUnit"
                  checked={values.isOrganisationalUnit}
                />
                Akreditira se organizacijska jedinica
              </label>
            </div>

            {unit}

            <p className="form-element-label">Pozivni broj</p>
            <div className="form-element"><input
              type="text"
              onChange={(e) => this.handleDialCodeChange(e, handleChange)}
              onBlur={handleBlur}
              value={values.phoneDialCode}
              name="phoneDialCode"
              placeholder="01"
            />
            {errors.phoneDialCode && touched.phoneDialCode &&
            <div className="form-element-feedback">{errors.phoneDialCode}</div>}
            </div>
            <p className="form-element-label">Telefon/mobitel</p>
           <div className="form-element"> <input
              type="text"
              onChange={(e) => this.handleNumberChange(e, handleChange)}
              onBlur={handleBlur}
              value={values.phoneNumber}
              name="phoneNumber"
              placeholder="0123456"
            />
            {errors.phoneNumber && touched.phoneNumber && <div className="form-element-feedback">{errors.phoneNumber}</div>}
           </div>
            <p className="form-element-label">Pozivni broj - faks</p>
            <div className="form-element"><input
              type="text"
              onChange={(e) => this.handleDialCodeChange(e, handleChange)}
              onBlur={handleBlur}
              value={values.faxDialCode}
              name="faxDialCode"
              placeholder="01"
            />
            {errors.faxDialCode && touched.faxDialCode && <div className="form-element-feedback">{errors.faxDialCode}</div>}
            </div>
            <p className="form-element-label">Faks</p>
           <div className="form-element"> <input
              type="text"
              onChange={(e) => this.handleNumberChange(e, handleChange)}
              onBlur={handleBlur}
              value={values.faxNumber}
              name="faxNumber"
              placeholder="0123456"
            />
            {errors.faxNumber && touched.faxNumber && <div className="form-element-feedback">{errors.faxNumber}</div>}
           </div>
            <p className="form-element-label">E-mail</p>
           <div className="form-element"> <input
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              name="email"
              placeholder="E-mail"
            />
            {errors.email && touched.email && <div className="form-element-feedback">{errors.email}</div>}
           </div>
            <p className="form-element-label">Osoba za kontakt</p>
           <div className="form-element"> <input
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.referredPerson}
              name="referredPerson"
              placeholder="Osoba za kontakt"
            />
            {errors.referredPerson && touched.referredPerson &&
            <div className="form-element-feedback">{errors.referredPerson}</div>}
           </div>
            <p className="form-element-label">Web stranica</p>
           <div className="form-element"> <input
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.webpage}
              name="webpage"
              placeholder="Web stranica"
            />
            {errors.webpage && touched.webpage && <div className="form-element-feedback">{errors.webpage}</div>}
           </div>
            <p className="form-element-label">Akreditacijska shema/norma</p>
            <div className="form-element">
              <AcreditationSchemaSelector
                schemas={schemas}
                onChange={(e) => this.handleAcreditationSchemaSelectorChange(e, schemas, handleChange, handleAcreditationSchemaChange)}
                onBlur={handleBlur}
                value={values.schema}
              />
              {errors.schema && touched.schema && <div className="form-element-feedback">{errors.schema}</div>}
            </div>
            {values.schema && (
            <>
               <p className="form-element-label">Područje akreditacije</p>
               <div className="form-element">

                   <AcreditationAreaSelector
                     areas={areas}
                     onChange={(e) => this.handleAcreditationAreaChange(e, values, setFieldValue)}
                     onBlur={handleBlur}
                     value={values.acreditationArea}
                     placeholder="Područje akreditacije"
                   />

                 {errors.acreditationArea && touched.acreditationArea && <div className="form-element-feedback">{errors.acreditationArea}</div>}
               </div>
            </>
            )}
            <p className="form-element-label">Područja akreditacije - ostalo</p>

            <div className="form-element form-element-textarea"> <textarea
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.acreditationAreaRemark}
              name="acreditationAreaRemark"
              placeholder="Područja akreditacije - ostalo"
            />
            {errors.acreditationAreaRemark && touched.acreditationAreaRemark &&
              <div className="form-element-feedback">{errors.acreditationAreaRemark}</div>}
           </div>
           <p className="form-element-label">Napomena</p>
           <div className="form-element form-element-textarea"> <textarea
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.remark}
              name="remark"
              placeholder="Napomena"
            />
            {errors.remark && touched.remark && <div className="form-element-feedback">{errors.remark}</div>}
           </div>
            <p className="form-element-label">Pravne napomene:</p>
            <label>
              <input
                type="checkbox"
                onChange={handleChange}
                onBlur={handleBlur}
                value="allowEmailSubmit"
                name="allowEmailSubmit"
                checked={values.allowEmailSubmit}
              />
              Suglasan/suglasna sam da se proces podnošenja e-upita za akreditaciju izvrši u potpunosti elektroničkim putem
            </label>
            {errors.allowEmailSubmit && touched.allowEmailSubmit && <><div className="form-element-feedback">{errors.allowEmailSubmit}</div></>}
            <label>
              <input
                type="checkbox"
                onChange={handleChange}
                onBlur={handleBlur}
                value="allowEmailReceival"
                name="allowEmailReceival"
                checked={values.allowEmailReceival}
              />
              Suglasan/suglasna sam da mi se prijavna dokumentacija isporuči elektroničkim putem od strane HAA
            </label>
            {errors.allowEmailReceival && touched.allowEmailReceival && <><div className="form-element-feedback">{errors.allowEmailReceival}</div></>}
          <button type="submit" className="btn-submit">Pošalji</button>
        </div>
      </form>
    );
  }
}
