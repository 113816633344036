import React, {Component} from "react";
import { Register } from "./Register";
import API from "../../API";

export class RegisterScreen extends Component {
  constructor() {
    super();

    this.state = {
      cities: [],
    }
  }

  async componentDidMount() {
    const cities = await this.getCities();

    this.setState({
      cities,
    });
  }

  async getCities() {
    const response = await API.get('wp-json/eupit/v1/city');

    return response.data;
  }

  render() {
    const { cities } = this.state;

    return (
      <section className="user-pages-container-register">
        <h2>Registracija</h2>
        <p>Registracija za pristup korisničkim stranicama HAA namijenjena je voditeljima postupaka u HAA, odobrenim
          ocjeniteljima i članovima radnih skupina Hrvatske akreditacijske agencije. Registrirani korisnik stječe pravo
          na pristup i korištenje dokumenata i ostalih sadržaja u skladu s pravilima HAA.</p>

        <h4>Ocjenitelji</h4>
        <p>Podaci koje navedete prilikom registracije bit će uspoređeni s podacima koje ste dostavili u životopisu
          prilikom upisa u Upisnik ocjenitelja HAA.</p>

        <h4>Članovi radnih skupina</h4>
        <p>Podaci koje navedete prilikom registracije bit će uspoređeni s podacima koje ima administrator za radne
          skupine.</p>

        <p>U slučaju da se navedeni podaci poklapaju, dobit ćete email s potrebnim informacijama za pristup
          dokumentaciji.</p>

        <p>Ukoliko niste zaprimili e-mail u roku 3 dana od dana registracije, molimo Vas da kontaktirate HAA na e-mail
          adresu za <a href="#">ocjenitelje</a> ili za <a href="#">članove radnih skupina</a>.</p>
        <Register cities={cities}/>
      </section>
    );
  }
}
