import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import './polyfill';
import App from './App';
import {InquiryScreen} from './screens/inquiry/InquiryScreen';
import AcreditationsRegister from './screens/AcreditationsRegister';
import * as serviceWorker from './serviceWorker';
import moment from 'moment/min/moment-with-locales';

moment.locale('hr');

const loginElement = document.getElementById('login-link-container');
if (loginElement) {

    const token = window.localStorage.getItem('token');
    const user = window.localStorage.getItem('authorizedUser');
    if (token && user) {
        const userData = JSON.parse(user);
        const firstName = userData && userData.firstName;
        const lastName = userData && userData.lastName;
        const fullName = (firstName || '') + ' ' +  (lastName || '');
        document.getElementById('login-link-container').innerHTML = '<span><a href="/korisnicke-stranice">' + fullName + '</a> (<a href="/korisnicke-stranice/#/logout">Odjava</a>)</span>'
    }
}

if (document.getElementById('user-pages-container')) {
    ReactDOM.render(<App/>, document.getElementById('user-pages-container'));
}

if (document.getElementById('eupit-container')) {
    ReactDOM.render(<InquiryScreen/>, document.getElementById('eupit-container'));
}

if (document.getElementById('accreditation-registry-container')) {
    ReactDOM.render(<AcreditationsRegister/>, document.getElementById('accreditation-registry-container'));
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
