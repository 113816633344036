import React, { Component } from 'react';

export class AcreditationAreaSelector extends Component<{
  areas: Object[];
  onChange: () => void;
  onBlur: () => void;
  value: String[];
}> {
  shouldComponentUpdate(nextProps, nextState) {
    const haveAreasChanged = !nextProps.areas.equals(this.props.areas);
    const haveSelectedAreasChanged = !nextProps.value.equals(this.props.value);

    return haveAreasChanged || haveSelectedAreasChanged;
  }

  render() {
    const { areas, value, ...rest } = this.props;
    return (
      <div className="area-selector">
        {areas && areas.map(area => (
          <label key={area.id}>
            <input
              type="checkbox"
              {...rest}
              id={area.id}
              value={area.name + '|' + area.id}
              name={area.name}
              checked={value.indexOf(area.name + '|' + area.id) !== -1}
            />
            {area.name}
          </label>
        ))}
      </div>
    );
  }
}
