import React from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import API from "../API";

const UpdatePasswordForm = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="update-password-form-elements">
        <div className="form-element">
          <input
            type="password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            name="password"
            placeholder="Nova zaporka"
          />
          {errors.password && touched.password && <div className="form-element-feedback">{errors.password}</div>}
        </div>
        <div className="form-element">
          <input
            type="password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.passwordRepeat}
            name="passwordRepeat"
            placeholder="Ponovi zaporku"
          />
          {errors.passwordRepeat && touched.passwordRepeat && <div className="form-element-feedback">{errors.passwordRepeat}</div>}
        </div>
      </div>
      <button type="submit" className="btn-submit">Promijeni</button>
    </form>
  );
};

const UpdatePassword = withFormik({
  mapPropsToValues: () => ({
    password: '',
    passwordRepeat: '',
  }),

  validationSchema: Yup.object().shape({
      password: Yup.string().min(7, 'Zaporka mora imati minimalno 7 znakova').required('Zaporka je obavezna').matches(/[0-9]/, 'Zaporka mora imati minimalno jedan broj').matches(/[a-z]/, 'Zaporka mora imati minimalno jedno malo slovo').matches(/[A-Z]/, 'Zaporka mora imati minimalno jedno veliko slovo'),
      passwordRepeat: Yup.string().oneOf([Yup.ref('password')], 'Zaporke moraju biti identične').required('Potvrda zaporke je obavezna'),
  }),

  handleSubmit: async (values, { props, setSubmitting }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`,
        'X-Request-Source': 'admin',
      },
    };

    const data = {
      "password": values.password,
    };

    try {
      const response = await API.post(
        `wp-json/wp/v2/users/${props.id}`,
        data,
        config
      );

      alert('Lozinka je uspješno ažurirana');
    } catch (error) {
      console.error(error);
      alert('Ažuriranje lozinke nije uspjelo');
    } finally {
      setSubmitting(false);
    }
  },

  displayName: 'UpdatePassword',
})(UpdatePasswordForm);

const UpdatePasswordScreen = props => ((
  <div>
    <p className="section-title">Promjena zaporke</p>
    <p className="article-content">Molimo unesite novu zaporku i ponovite ju radi sigurnosnih razloga.</p>
    <UpdatePassword id={props.id}/>
  </div>
));

export default UpdatePasswordScreen;