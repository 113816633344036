import React, { Component } from 'react';

export class CitySelector extends Component<{
  cities: Object[];
  onChange: () => void;
  onBlur: () => void;
  name: string;
  value: string;
}> {
  shouldComponentUpdate(nextProps, nextState) {
    const haveCitiesChanged = nextProps.cities !== this.props.cities;
    const hasSelectedCityChanged = nextProps.value !== this.props.value;

    return haveCitiesChanged || hasSelectedCityChanged;
  }

  render() {
    const { cities, ...rest } = this.props;

    return (
      <select
        {...rest}
      >
        <option value="" disabled>Odaberite grad</option>
        {cities && cities.map(city => {
          const cityValue = !city.postalcode || city.postalcode === '_' ? city.city : `${city.city} ${city.postalcode}`;

          return (<option key={city.id} value={city.city + '|' + city.id}>{cityValue}</option>)
        })}
      </select>
    );
  }
}
