import React, { Component } from 'react';
import uuid from 'uuid/v4';
import API from "../API";

class Documents extends Component {
  constructor() {
    super();



    this.state = {
      documents: []
    };
  }

  async componentDidMount() {
    const response = await API.get('wp-json/eupit/v1/ocjenitelji');
    const docs = response.data;

    //group by parent category

    const groups = {
        'Other' : [],
    };

    for(let i = 0; i < docs.length; i+=1) {
        if(docs[i] && docs[i].parentCategory && docs[i].parentCategory.name) {
            if(docs[i].parentCategory.name === 'public') {
                continue;
            }
            if(!groups[docs[i].parentCategory.name]) {
                groups[docs[i].parentCategory.name] = [];
            }
            groups[docs[i].parentCategory.name].push(docs[i])
        }
    }


    const documents = [];

    const groupsList = Object.keys(groups).sort((a, b) => {
        return a.toLocaleLowerCase().localeCompare(b.toLowerCase());
    });

    for(let i = 0; i < groupsList.length; i+=1) {
        if(groups[groupsList[i]].length === 0) {
            continue;
        }

        documents.push({
            id: uuid(),
            title: groupsList[i],
            attachments: groups[groupsList[i]].map(e => {
                return {
                    id: e.ID,
                    title: e.post_title,
                    url: e.guid,
                }
            })
        })
    }
    this.setState({
      documents: documents
     });
  }



  handleDocumentClicked = (id) => {
    this.setState({
      documents: this.state.documents.map(document => {
        if (document.id === id) {
          return {
            ...document,
            clicked: !document.clicked,
          };
        } else {
          return {
            ...document,
            clicked: false,
          };
        }
      })
    });
  };

  render() {
    const documentsJSX = this.state.documents.map(document => (
      <div key={document.id}>
        <img src="/wp-content/themes/haa/assets/rectangle.svg" className="rectangle" />
        <span className="document-title hoverable" onClick={() => this.handleDocumentClicked(document.id)}>
          {document.title}
        </span>
        <div>
          {document.clicked && document.attachments.map(attachment => (
            <a href={attachment.url} key={attachment.id} className="attachment hoverable">
              {attachment.title}
            </a>
          ))}
        </div>
      </div>
    ));

    return (
      <div className="documents-screen">
        <p className="section-title">Dokumentacija sustava upravljanja HAA namijenjena ocjeniteljima</p>
        <p className="article-content">
          Na ovoj stranici omogućili smo Vam pristup dokumentima s Popisa odobrenih dokumenata prema postupku HAA-P-5/3-1.

          Molimo Vas da dostupne dokumente koristite isključivo u postupcima akreditacije koje obavljate za HAA.

          Podsjećamo Vas da ste potpisali Sporazum o suradnji s HAA kojim ste se obvezali da ćete kao poslovnu tajnu čuvati sve podatke koje ste saznali ili ćete saznati tijekom rada za HAA.
        </p>
        <div className="documents-list">
          {documentsJSX}
        </div>
      </div>
    );
  }
}

export default Documents;
