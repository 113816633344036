import React, { Component } from 'react';

export class AcreditationSchemaSelector extends Component<{
  schemas: Object[];
  onChange: () => void;
  onBlur: () => void;
  value: string;
}> {
  shouldComponentUpdate(nextProps, nextState) {
    const haveSchemasChanged = nextProps.schemas !== this.props.schemas;
    const hasSelectedSchemaChanged = nextProps.value !== this.props.value;

    return haveSchemasChanged || hasSelectedSchemaChanged;
  }

  render() {
    const { schemas, ...rest } = this.props;
    return (
      <select
        {...rest}
        name="schema"
      >
        <option value="" disabled>Odaberite akreditacijsku shemu/normu</option>
        {schemas && schemas.map(schema => (<option key={schema.id} id={schema.id} value={schema.name + ' ' + schema.normName + '|' + schema.id}>{schema.name} {schema.normName}</option>))}
      </select>
    );
  }
}
