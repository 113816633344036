import React, { Component } from 'react';
import API from "../API";
import moment from 'moment/min/moment-with-locales';

// pretraga po datumu
// u norme dodati 17025:2007 i da obuhvaca i ispitne i umjerne
// dodati dokumentaciju
// dodati replace datoteke u media
// hideati media tab
// fix excel kod filterina

class AcreditationsRegister extends Component {
  constructor(props) {
    super(props);

    this.ACREDITATIONS_PER_PAGE = 5;
    this.PAGE_NUMBERS_PER_PAGE = 7;
    this.isEnglish = window.location.pathname && window.location.pathname.startsWith('/en/')
    this.state = {
      pages: {},
      searchTerm: '',
      searchTermNorm: '',
      searchTermName: '',
      searchTermSchema: '',
      searchTermRulebookId: '',
      schemas: [],
      selectedPage: 1,
      sort: 1,
      accreditations: [],
      filteredAcreditations: false,
    };
  }

  async componentDidMount() {
    const accreditations = await this.getAcreditations();
    const schemas = await this.getAllSchemas();
    const sortedAccreditations = this.sortAcreditations(accreditations);
    this.setState({
      accreditations: accreditations,
      schemas: schemas,
      pages: this.paginateAcreditations(sortedAccreditations || []),
    });
  };

  remove_duplicates = (arr, property) => {
    return [...arr.reduce((map, obj) => map.set(obj[property], obj), new Map()).values()];
  }

  getAcreditations = async () => {
    const config = {
      headers: {
      },
    };

    let token = localStorage.getItem('token');
    if(token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    const response = await API.get('wp-json/eupit/v1/institution-registry', config);

    return response.data;
  };

  getAllSchemas = async () => {
    const response = await API.get('wp-json/eupit/v1/schema', {});

    const schemasData = (response.data ||[]).map(e => {
      return {
          name: e.name,
          normName: e.normName,
          name_en: e.name
      }
    });
    console.log(schemasData);
    return this.remove_duplicates(schemasData, 'name');
  }


  paginateAcreditations = (acreditations) => {
    const pages = {};
    let page = -1;

    for (let i = 0; i < acreditations.length; i++) {
      if (i % this.ACREDITATIONS_PER_PAGE === 0) {
        page++;
        pages[page] = [];
      }

      pages[page].push(acreditations[i]);
    }

    return pages;
  };

  sortAcreditations = (acreditations, sort = 1) => {
    return acreditations.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()) * sort);
  };

  filterAcreditations = (acreditations, searchTerm) => {
    searchTerm = searchTerm.toLowerCase();

    const parts = (searchTerm || '').split(',').map(e => e.trim());
    let filteredAcreditations = acreditations;

    for(let i = 0; i < parts.length; i+=1) {
      filteredAcreditations = filteredAcreditations.filter(acreditation => {
        return (acreditation.title && acreditation.title.toLowerCase().includes(parts[i]))
            || (acreditation.address && acreditation.address.toLowerCase().includes(parts[i]))
            || (acreditation.area && acreditation.area.toLowerCase().includes(parts[i]))
            || (acreditation.content && acreditation.content.toLowerCase().includes(parts[i]))
            || (acreditation.type && acreditation.type.toLowerCase().includes(parts[i]))
            || (acreditation.rulebook_id && acreditation.rulebook_id.toLowerCase().includes(parts[i]))
            || (acreditation.id && acreditation.id.toLowerCase().includes(parts[i]))
            || (acreditation.norm && acreditation.norm.toLowerCase().includes(parts[i]))
      });
    }

    return filteredAcreditations;
  };

  filterAcreditationsRulebookId = (acreditations, searchTerm) => {
    searchTerm = searchTerm.toLowerCase();

    return acreditations.filter(acreditation => {
      return (acreditation.type && acreditation.type.toLowerCase().includes(searchTerm))
    });
  };

  filterAcreditationsName = (acreditations, searchTerm) => {
    searchTerm = searchTerm.toLowerCase();

    return acreditations.filter(acreditation => {
      return (acreditation.title && acreditation.title.toLowerCase().includes(searchTerm))
    });
  };

  filterAcreditationsNorm = (acreditations, searchTerm) => {
    searchTerm = searchTerm.toLowerCase();

    if(searchTerm === "") {
      return acreditations;
    }
    return acreditations.filter(acreditation => {
      return acreditation.norm && acreditation.norm.toLowerCase().includes(searchTerm)
    });
  };

  handleSortClicked = () => {
    const { searchTerm, sort, accreditations } = this.state;

    const filteredAcreditations = this.filterAcreditations(accreditations, searchTerm);
    const sortedAcreditations = this.sortAcreditations(filteredAcreditations, -sort);
    const paginatedAcreditations = this.paginateAcreditations(sortedAcreditations);

    this.setState({
      pages: paginatedAcreditations,
      sort: -sort,
    });
  };

  handleFullDownload = () => {
    const { searchTerm,
      searchTermNorm,
      searchTermName,
      searchTermSchema,
      searchTermRulebookId,
    } = this.state;

    window.location = '/wp-json/eupit/v1/institution-registry/xlsx?query=' + encodeURIComponent(searchTerm) + '&norm=' + encodeURIComponent(searchTermNorm) + '&name=' + encodeURIComponent(searchTermName) + '&schema=' + encodeURIComponent(searchTermSchema) + '&norma=' + encodeURIComponent(searchTermRulebookId);

  };

  handleSearchUpdate = (event) => {
    const { sort, accreditations } = this.state;
    const searchTerm = event.target.value;

    let filteredAcreditations = this.filterAcreditations(accreditations, searchTerm); //todo: dodaj , pa split
    if (this.state.searchTermNorm) {
      filteredAcreditations = this.filterAcreditationsNorm(filteredAcreditations, this.state.searchTermNorm);
    }
    if (this.state.searchTermSchema) {
      filteredAcreditations = this.filterAcreditationsRulebookId(filteredAcreditations, this.state.searchTermSchema);
    }
    if (this.state.searchTermName) {
      filteredAcreditations = this.filterAcreditationsName(filteredAcreditations, this.state.searchTermName);
    }
    const sortedAcreditations = this.sortAcreditations(filteredAcreditations, sort);
    const paginatedAcreditations = this.paginateAcreditations(sortedAcreditations);

    this.setState({
      pages: paginatedAcreditations,
      filteredAcreditations,
      searchTerm,
      selectedPage: 1,
    });
  };

  handleSearchUpdateName = (event) => {
    const { sort, accreditations } = this.state;
    const searchTerm = event.target.value;

    let filteredAcreditations = this.filterAcreditationsName(accreditations, searchTerm);
    if (this.state.searchTerm) {
      filteredAcreditations = this.filterAcreditations(filteredAcreditations, this.state.searchTerm);
    }
    if (this.state.searchTermNorm) {
      filteredAcreditations = this.filterAcreditationsNorm(filteredAcreditations, this.state.searchTermNorm);
    }
    if (this.state.searchTermSchema) {
      filteredAcreditations = this.filterAcreditationsRulebookId(filteredAcreditations, this.state.searchTermSchema);
    }

    const sortedAcreditations = this.sortAcreditations(filteredAcreditations, sort);
    const paginatedAcreditations = this.paginateAcreditations(sortedAcreditations);

    this.setState({
      pages: paginatedAcreditations,
      filteredAcreditations,
      searchTermName: searchTerm,
      selectedPage: 1,
    });
  };

  handleSearchUpdateRulebookId = (event) => {
    const { sort, accreditations } = this.state;
    const searchTerm = event.target.value;

    let filteredAcreditations = this.filterAcreditationsRulebookId(accreditations, searchTerm);
    if (this.state.searchTerm) {
      filteredAcreditations = this.filterAcreditations(filteredAcreditations, this.state.searchTerm);
    }
    if (this.state.searchTermNorm) {
      filteredAcreditations = this.filterAcreditationsNorm(filteredAcreditations, this.state.searchTermNorm);
    }
      if (this.state.searchTermName) {
          filteredAcreditations = this.filterAcreditationsName(filteredAcreditations, this.state.searchTermName);
      }
    const sortedAcreditations = this.sortAcreditations(filteredAcreditations, sort);
    const paginatedAcreditations = this.paginateAcreditations(sortedAcreditations);

    this.setState({
      pages: paginatedAcreditations,
      filteredAcreditations,
      searchTermSchema: searchTerm,
      selectedPage: 1,
    });
  };

  handleSearchNormUpdate = (event) => {
    if(!event || !event.target) {
      return;
    }
    const { sort, accreditations } = this.state;
    console.log('norm search', event.target.value)

    const searchTermNorm = event.target.value || "";
    let filteredAcreditations = this.filterAcreditationsNorm(accreditations, searchTermNorm);
    if (this.state.searchTerm) {
      filteredAcreditations = this.filterAcreditations(filteredAcreditations, this.state.searchTerm);
    }
    if (this.state.searchTermSchema) {
        filteredAcreditations = this.filterAcreditationsRulebookId(filteredAcreditations, this.state.searchTermSchema);
    }
      if (this.state.searchTermName) {
          filteredAcreditations = this.filterAcreditationsName(filteredAcreditations, this.state.searchTermName);
      }
    const sortedAcreditations = this.sortAcreditations(filteredAcreditations, sort);
    const paginatedAcreditations = this.paginateAcreditations(sortedAcreditations);

    this.setState({
      pages: paginatedAcreditations,
      filteredAcreditations,
      searchTermNorm: event.target.value,
      selectedPage: 1,
    });
  };

  handlePageClicked = (pageNumber) => {
    this.setState({
      selectedPage:  pageNumber,
    });
  };

  generatePaginationJSX = (pages, selectedPage) => {
    // determine maximum page number in pagination bar
    const maxPageNumber = Object.keys(pages).length;
    let paginationJSX = [];

    const isSelectedPageInTheBeginning = selectedPage <= this.PAGE_NUMBERS_PER_PAGE - 2;
    const isSelectedPageInTheEnd = selectedPage > (maxPageNumber - this.PAGE_NUMBERS_PER_PAGE + 2);
    const isSelectedPageInTheMiddle = !isSelectedPageInTheBeginning && !isSelectedPageInTheEnd;

    for (let i = 1; i <= maxPageNumber; i++) {
      const isFirstPage = i === 1;
      const isLastPage = i === maxPageNumber;

      const renderCondition = (
        isFirstPage
        || isLastPage
        || (isSelectedPageInTheBeginning && i <= this.PAGE_NUMBERS_PER_PAGE - 1)
        || (isSelectedPageInTheEnd && i >= (maxPageNumber - this.PAGE_NUMBERS_PER_PAGE + 2))
        || (isSelectedPageInTheMiddle && (i >= selectedPage - 2 && i <= selectedPage + 2))
      );
      const renderEllipsis = (
        (isSelectedPageInTheBeginning && i === (this.PAGE_NUMBERS_PER_PAGE - 1))
        || (isSelectedPageInTheEnd && i === (maxPageNumber - this.PAGE_NUMBERS_PER_PAGE + 2))
        || (isSelectedPageInTheMiddle && (i === selectedPage - 2 || i === selectedPage + 2))
      );

      if (renderCondition) {
        paginationJSX.push(<button key={i} className={i === selectedPage ? 'hoverable selected' : 'hoverable'} onClick={() => this.handlePageClicked(i)}>{renderEllipsis ? '…' : i}</button>);
      }
    }

    return paginationJSX;
  };

  generateAcreditationsJSX = (pages, selectedPage) => {
    let acreditationsJSX;

    if (pages[selectedPage - 1]) {
      acreditationsJSX = pages[selectedPage - 1].map(acreditation => {

        if(acreditation.pdf_attachment && acreditation.pdf_attachment.indexOf('.pdf') > -1) {
          acreditation.pdf_attachment = acreditation.pdf_attachment.slice(0,-4);
        }
        let url = '/akreditacija/files/read.php?re='+ acreditation.id +'__akredFile';
        if(acreditation.rulebook_id) {
          url += '&wr=' + acreditation.rulebook_id;
        }
        return (
            <div key={acreditation.id} className="list-card">
              <div className="card-header">
                <div className="card-header-headline">
                  <p className="card-header-title">{acreditation.title}</p>
                  <p className="card-header-id">{acreditation.rulebook_id || acreditation.id}</p>
                </div>
                <div className="card-header-info">
                  <a href={acreditation.url && acreditation.url.startsWith('http') ? acreditation.url : 'http://' + acreditation.url} target='_blank' rel="noopener noreferrer">
                    {acreditation.url}
                  </a>
                  <p>{acreditation.address}, {acreditation.city} {acreditation.postalCode}</p>
                  <p>{acreditation.contact} {acreditation.phone ? '(tel: ' + acreditation.phone + ')' : ''}</p>
                </div>
              </div>
              <div className="card-data">
                <p className="card-data-property-name">Norma</p>
                <p className="card-data-property-value">{acreditation.norm}</p>
                <p className="card-data-property-name">Shema</p>
                <p className="card-data-property-value">{acreditation.type}</p>
                <p className="card-data-property-name">Područje</p>
                <p className="card-data-property-value">{acreditation.area}</p>
                <p className="card-data-property-name">Akreditacija istječe</p>
                <p className="card-data-property-value">{moment(acreditation.validUntil).format('DD.MM.YYYY.')}</p>
                <p className="card-data-property-name"><a href={url}>Prilog</a></p>
              </div>
            </div>
        )
      });
    }

    return acreditationsJSX;
  };

  render() {
    const { pages, selectedPage, accreditations, filteredAcreditations, searchTerm, searchTermSchema, searchTermName, searchTermNorm, searchTermRulebookId } = this.state;

    const paginationJSX = this.generatePaginationJSX(pages, selectedPage);
    const acreditationsJSX = this.generateAcreditationsJSX(pages, selectedPage);

    let totalText = 'Ukupno akreditiranih';
    let totalLength = accreditations ? accreditations.length : 0;
    if(searchTerm !== '' || searchTermSchema !== '' || searchTermName  !== '' || searchTermNorm !== '' || searchTermRulebookId !== '') {
      totalText = 'Ukupno nađenih';
      totalLength = filteredAcreditations ? filteredAcreditations.length : 0;
    }

    const normInput = (this.state.schemas || []).map(s => {
      return (<option value={s.normName}>{s.normName}</option>)
    })
    normInput.push(<option value="HRN EN ISO/IEC 17025:2007">HRN EN ISO/IEC 17025:2007</option>)

    const schemaInput = (this.state.schemas || []).map(s => {
      return (<option value={s.name}>{s.name}</option>) //TODO: samo pisati 17025: bez godine
    })

    return (
      <div className="accreditations-screen">
        <aside className="registry-content-filters">
          <h3>Traži</h3>
          <div>
            <input type="text" name="search" placeholder="Ključna riječ" value={this.state.searchTerm} onChange={this.handleSearchUpdate}/>
            <input type="text" name="searchTermName" placeholder="Naziv Tijela" value={this.state.searchTermName} onChange={this.handleSearchUpdateName}/>
            {/*<input type="text" name="searchRulebookId" placeholder="Shema" value={this.state.searchTermSchema} onChange={this.handleSearchUpdateRulebookId}/>*/}
            {this.state.schemas.length > 0 &&
            (<select value={this.state.searchTermSchema} onChange={this.handleSearchUpdateRulebookId}>
              <option value={""}>{"Shema"}</option>
              {schemaInput}
            </select>)
            }

            {this.state.schemas.length > 0 &&
            (<select value={this.state.searchTermNorm} onChange={this.handleSearchNormUpdate}>
              <option value={""}>{"Sve norme"}</option>
              {normInput}
            </select>)
            }
          </div>
          {this.isEnglish ?
              (<p>If the conformity assessment body (CAB) is not listed in the Registry of accreditations, it is considered that it has no granted accreditation or its accreditation is suspended or withdrawn.
                For further information, check the status in the <a href="/en/registry-of-suspended-and-withdrawn-accreditations/">Registry of suspended and withdrawn accreditations</a> or send request to email:
                <a href="mailto:akreditacija@akreditacija.hr">akreditacija@akreditacija.hr</a>
              </p>) :
              (<p>Ukoliko se tijelo za ocjenjivanje sukladnosti (TOS) ne nalazi u Registru akreditacija,
                tijelo nije akreditirano ili mu je akreditacija suspendirana ili povučena.
                Za dodatne informacije, provjerite status u <a href="/registar-suspendiranih-i-povucenih-akreditacija/">Registru suspendiranih i povučenih akreditacija</a> ili pošaljite upit na email:
                <a href="mailto:akreditacija@akreditacija.hr">akreditacija@akreditacija.hr</a>
              </p>)}
        </aside>
        <section className="registry-content-list">
          <div className="top-bar">
            <div className="sort-bar">
              <button onClick={this.handleSortClicked}>{this.state.sort === -1 ? 'Sortiraj po nazivu (A-Z)' : 'Sortiraj po nazivu (Z-A)'}</button>
              <button onClick={this.handleFullDownload}>{'Preuzmi registar (Excel)'}</button>
            </div>
            <p>{totalText}: <span>{totalLength}</span></p>
          </div>
          <div className="acreditations">{acreditationsJSX}</div>
          <div className="pagination-bar">{paginationJSX}</div>
        </section>
      </div>
    );
  }
}

export default AcreditationsRegister;
