import React, {Component} from 'react';
import { withFormik } from 'formik';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import API from '../API';
import {RegisterScreen} from "./registration/RegisterScreen";

const LoginForm = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
  } = props;

  return (
    <form onSubmit={handleSubmit} className="login-form">
      <div className="login-form-elements">
        <p className="form-element-label">Korisničko ime</p>
        <div className="form-element">
          <input
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.username}
            name="username"
            placeholder="Korisničko ime"
          />
          {errors.username && touched.username && <div className="form-element-feedback">{errors.username}</div>}
        </div>
        <p className="form-element-label">Zaporka</p>
        <div className="form-element">
          <input
            type="password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            name="password"
            placeholder="Zaporka"
          />
          {errors.password && touched.password && <div className="form-element-feedback">{errors.password}</div>}
        </div>
        <button className="btn-submit" type="submit">Prijavi se</button>
      </div>
    </form>
  );
};

const Login = withFormik({
  mapPropsToValues: () => ({
    username: '',
    password: '',
  }),

  validationSchema: Yup.object().shape({
    username: Yup.string().required('Korisničko ime je obavezno'),
    password: Yup.string().required('Zaporka je obavezna'),
  }),

  handleSubmit: async (values, { props, setSubmitting }) => {
    const bodyFormData = new FormData();

    bodyFormData.append('username', values.username);
    bodyFormData.append('password', values.password);

    try {
      const response = await API.post('wp-json/jwt-auth/v1/token', bodyFormData);
      const { token } = response.data;

      const user = await API.get('wp-json/wp/v2/users/me', { headers: {
          'Authorization': `Bearer ${response.data.token}`,
          'X-Request-Source': 'admin',
        },
      });

      props.setUser({
        id: user.data.id,
        firstName: user.data.firstName,
        lastName: user.data.lastName,
        email: user.data.user_email,
        address: user.data.meta.address,
        location: user.data.meta.city,
        phoneNumber: user.data.meta.phoneNumber,
        roleList: user.data.roleList,
        zip: user.data.meta.zipcode,
        allowDataSharing: user.data.meta.isPublicProfile,
      });

      window.localStorage.setItem('token', token);
      window.localStorage.setItem('authorizedUser', JSON.stringify(user.data));
      //TODO: update this somehow
      const userData = user.data;
      const firstName = userData && userData.firstName;
      const lastName = userData && userData.lastName;
      const fullName = (firstName || '') + ' ' +  (lastName || '');
      document.getElementById('login-link-container').innerHTML = '<span><a href="/korisnicke-stranice">' + fullName + '</a> (<a href="/korisnicke-stranice/#/logout">Odjava</a>)</span>'

      props.setToken(token);
    } catch (error) {
      console.error(error);
      alert('Invalid username/password');
    } finally {
      setSubmitting(false);
    }
  },

  displayName: 'Login',
})(LoginForm);

export class LoginScreen extends Component {
  constructor() {
    super();

    this.state = {
      showRegistrationForm: false,
    };
  }

  toggleRegistrationForm = () => {
    this.setState({
      showRegistrationForm: !this.state.showRegistrationForm,
    });
  };

  render() {
    const { showRegistrationForm } = this.state;

    return (
      <div className="user-pages">
        <h1>Korisničke stranice</h1>
        <div className="user-pages-container">
          <section className="user-pages-container-login">
            <h2>Prijava</h2>
            <Login {...this.props}/>
            <span onClick={this.toggleRegistrationForm}>Postanite korisnik</span>
            <a href="/wp-login.php?action=lostpassword"><span>Zaboravljena lozinka?</span></a>
          </section>
          {showRegistrationForm && (<RegisterScreen />)}
        </div>
      </div>
    );
  }
}

export default withRouter(LoginScreen);