import { withFormik } from 'formik';
import * as Yup from 'yup';
import { InquiryForm } from "./InquiryForm";
import API from "../../API";

const initialValues = {
  acreditationArea: [],
  acreditationAreaRemark: '',
  address: '',
  allowEmailReceival: false,
  allowEmailSubmit: false,
  city: '',
  email: '',
  isOrganisationalUnit: false,
  faxDialCode: '',
  faxNumber: '',
  OIB: '',
  organisation: '',
  organisationalUnitAddress: '',
  organisationalUnitCity: '',
  organisationalUnitName: '',
  phoneDialCode: '',
  phoneNumber: '',
  referredPerson: '',
  remark: '',
  schema: '',
  webpage: '',
};

export const Inquiry = withFormik({
  mapPropsToValues: (props) => (initialValues),

  validationSchema: Yup.object().shape({
      acreditationArea: Yup.array().required('Područje akreditacije je obavezno'),
      acreditationAreaRemark: Yup.string().max(250, 'Dodatan opis područja akreditacije može imati maksimalno 250 znakova'),
      address: Yup.string().required('Ulica i kućni broj su obavezni').min(5, 'Ulica i kućni broj moraju imati minimalno 5 znakova'),
      allowEmailReceival: Yup.boolean().oneOf([true], 'Suglasnost za isporuku prijavne dokumentacije elektroničkim putem se mora prihvatiti'),
      allowEmailSubmit: Yup.boolean().oneOf([true], 'Suglasnost za podnošenje e-upta za ekreditaciju elektroničkim putem se mora prihvatiti'),
      city: Yup.string().required('Grad je obavezan'),
      email: Yup.string().email('Neispravna email adresa').required('Email adresa je obavezna'),
      isOrganisationalUnit: Yup.boolean(),
      faxDialCode: Yup.string().min(2, 'Pozivni broj faxa mora imati minimalno 2 znaka'),
      faxNumber: Yup.string().min(6, 'Broj faxa mora imati minimalno 6 znakova'),
      OIB: Yup.number().required('OIB je obavezan').min(11, 'OIB mora imati minimalno 11 znakova'),
      organisation: Yup.string().required('Ime organizacije je obavezno').min(5, 'Ime organizacije mora imati minimalno 5 znakova'),
      organisationalUnitAddress: Yup.string().when(
        'isOrganisationalUnit',
        {
          is: true,
          then: Yup.string().required('Adresa organizacijske jedinice je obavezna'),
          otherwise: Yup.string(),
        }
      ),
      organisationalUnitCity: Yup.string().when(
        'isOrganisationalUnit',
        {
          is: true,
          then: Yup.string().required('Grad organizacijske jedinice je obavezan'),
          otherwise: Yup.string(),
        }
      ),
      organisationalUnitName: Yup.string().when(
        'isOrganisationalUnit',
        {
          is: true,
          then: Yup.string().required('Naziv organizacijske jedinice je obavezan'),
          otherwise: Yup.string(),
        }
      ),
      phoneDialCode: Yup.string().required('Pozivni broj telefona/mobitela je obavezan').min(2, 'Pozivni broj telefona/mobitela mora imati minimalno 2 znaka').matches(/\d{2,3}/, 'Dozvoljeni su samo brojevi'),
      phoneNumber: Yup.string().required('Broj telefona/mobitela je obavezan').min(6, 'Broj telefona/mobitela mora imati minimalno 6 znakova'),
      referredPerson: Yup.string().required('Osoba za kontakt je obavezna').min(5, 'Osoba za kontakt mora imati minimalno 5 znakova').matches(/\D*/, 'Osoba za kontakt smije sadržavati samo slova i znakove'),
      remark: Yup.string().max(250, 'Vaša poruka može imati maksimalno 250 znakova'),
      schema: Yup.string().required('Akreditacijska shema/norma je obavezna'),
      webpage: Yup.string().url('Neispravan URL (http://stranica.hr)'),
  }),

  handleSubmit: async (values, { props, resetForm, setSubmitting }) => {
    setSubmitting(true);
    const config = {
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${window.localStorage.getItem('token')}`,
      },
    };

    const data = {
      "acreditation_area": values.acreditationArea,
      "acreditation_area_remark": values.acreditationAreaRemark,
      "address": values.address,
      "allowEmailReceival": values.allowEmailReceival,
      "allowEmailSubmit": values.allowEmailSubmit,
      "city": values.city,
      "email": values.email,
      "faxDialCode": values.faxDialCode,
      "faxNumber": values.faxNumber,
      "OIB": values.OIB,
      "organisation": values.organisation,
      "phoneDialCode": values.phoneDialCode,
      "phoneNumber": values.phoneNumber,
      "referredPerson": values.referredPerson,
      "remark": values.remark,
      "schema": values.schema,
      "webpage": values.webpage,
    };

    if (values.isOrganisationalUnit) {
      data['organisationalUnitAddress'] = values.organisationalUnitAddress;
      data['organisationalUnitCity'] = values.organisationalUnitCity;
      data['organisationalUnitName'] = values.organisationalUnitName;
    }

    try {
      const response = await API.post('wp-json/eupit/v1/submit', data, config);

      alert('Poštovani, Vaša prijava je uspješna. Dodatne informacije zaprimit ćete e-mailom.');
      resetForm(initialValues);
    } catch (error) {
      console.error(error);
      alert('Došlo je do greške prilikom slanja E-upita.');
    } finally {
      setSubmitting(false);
    }
  },

  displayName: 'Inquiry',
})(InquiryForm);
