import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

class Sidebar extends Component {
  handleLogout = () => {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('authorizedUser');
    this.props.resetUser();
  };

  render() {
    return (
      <div className="sidebar">
        <div className="sidebar-list">
          <Link to="/news">Obavijesti</Link>
          <Link to="/documents">Dokumentacija</Link>
          <Link to="/address-book">Adresar korisnika</Link>
          <Link to="/update-password">Promjena lozinke</Link>
          <Link to="/update-profile">Promjena podataka</Link>
          <a href="" onClick={this.handleLogout}>Odjava</a>
        </div>
      </div>
    );
  }
}

export default withRouter(Sidebar);