import React from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import API from "../API";

const UpdateProfileForm = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="update-profile-form-elements">
        <p className="form-element-label">Ime</p>
        <div className="form-element">
          <input
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.firstName}
            name="firstName"
          />
          {errors.firstName && touched.firstName && <div className="form-element-feedback">{errors.firstName}</div>}
        </div>
        <p className="form-element-label">Prezime</p>
        <div className="form-element">
          <input
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastName}
            name="lastName"
          />
          {errors.lastName && touched.lastName && <div className="form-element-feedback">{errors.lastName}</div>}
        </div>
        <p className="form-element-label">Email adresa</p>
        <div className="form-element">
          <input
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            name="email"
          />
          {errors.email && touched.email && <div className="form-element-feedback">{errors.email}</div>}
        </div>
        <p className="form-element-label">Adresa</p>
        <div className="form-element">
          <input
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.address}
            name="address"
          />
          {errors.address && touched.address && <div className="form-element-feedback">{errors.address}</div>}
        </div>
        <p className="form-element-label">Mjesto</p>
        <div className="form-element">
          <input
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.location}
            name="location"
          />
          {errors.location && touched.location && <div className="form-element-feedback">{errors.location}</div>}
        </div>
        <p className="form-element-label">Poštanski broj</p>
        <div className="form-element">
          <input
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.zip}
            name="zip"
          />
          {errors.zip && touched.zip && <div className="form-element-feedback">{errors.zip}</div>}
        </div>
        <p className="form-element-label">Telefon: </p>
        <div className="form-element">
          <input
            type="number"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.phoneNumber}
            name="phoneNumber"
          />
          {errors.phoneNumber && touched.phoneNumber && <div className="form-element-feedback">{errors.phoneNumber}</div>}
        </div>
        <p className="form-element-label">Privatnost podataka: </p>
        <div className="form-element">
          <label>
            <input
              type="radio"
              onChange={handleChange}
              onBlur={handleBlur}
              value="dopusti"
              id="allowDataSharing1"
              name="allowDataSharing"
              checked={values.allowDataSharing === 'dopusti'}
            />
            Želim da su moji osobni podaci dostupni drugim korisnicima
          </label>
          <label>
            <input
              type="radio"
              onChange={handleChange}
              onBlur={handleBlur}
              value="zabrani"
              id="allowDataSharing2"
              name="allowDataSharing"
              checked={values.allowDataSharing === 'zabrani'}
            />
            Ne želim da su moji osobni podaci dostupni drugim korisnicima
          </label>
          {errors.allowDataSharing && touched.allowDataSharing && <div className="form-element-feedback">{errors.allowDataSharing}</div>}
        </div>
        <p className="form-element-label">Korisnička grupa: </p>
        <div className="form-element">
          <label className="text-uppercase">
            <input
              type="checkbox"
              onChange={handleChange}
              onBlur={handleBlur}
              value="radna-skupina"
              name="group"
              checked={values.group}
            />
            Radna skupina
          </label>
          {errors.group && touched.group && <div className="form-element-feedback">{errors.group}</div>}
        </div>
      </div>
      <button type="submit" className="btn-submit">Promijeni</button>
    </form>
  );
};

const UpdateProfile = withFormik({
  mapPropsToValues: (props) => ({
    firstName: props.firstName,
    lastName: props.lastName,
    email: props.email,
    address: props.address,
    location: props.location,
    zip: props.zip,
    phoneNumber: props.phoneNumber,
    allowDataSharing: props.allowDataSharing ? 'dopusti' : 'zabrani',
    group: false,
  }),

  validationSchema: Yup.object().shape({
    firstName: Yup.string().required('Ime je obavezno'),
    lastName: Yup.string().required('Prezime je obavezno'),
    email: Yup.string().email('Neispravna email adresa').required('Email adresa je obavezna'),
    address: Yup.string().required('Adresa je obavezna'),
    location: Yup.string().required('Lokacija je obavezna'),
    zip: Yup.string().required('Poštanski kod je obavezan'),
    phoneNumber: Yup.number().required('Broj telefona je obavezan'),
    allowDataSharing: Yup.string().required('Obavezno je odabrati opciju vezanu uz privatnost podataka'),
    group: Yup.boolean(),
  }),

  enableReinitialize: true,

  handleSubmit: async (values, { props, setSubmitting }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`,
        'X-Request-Source': 'admin',
      },
    };
    const data = {
      "first_name": values.firstName,
      "last_name": values.lastName,
      "email": values.email,
      "meta": {
        "address": values.address,
        "city": values.location,
        "zipcode": values.zip,
        "phoneNumber": values.phoneNumber,
        "isPublicProfile": values.isPublicProfile,
      }
    };

    try {
      const response = await API.post(
        `wp-json/wp/v2/users/${props.id}`,
        data,
        config
      );

      props.setUser({
        id: response.data.id,
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        email: response.data.email,
        address: response.data.meta.address,
        location: response.data.meta.city,
        phoneNumber: response.data.meta.phoneNumber,
        zip: response.data.meta.zipcode,
        allowDataSharing: response.data.meta.isPublicProfile,
      });

      alert('Profil je uspješno ažuriran');
    } catch (error) {
      console.error(error);
      alert('Ažuriranje profila nije uspjelo');
    } finally {
      setSubmitting(false);
    }
  },

  displayName: 'UpdatePassword',
})(UpdateProfileForm);

const UpdateProfileScreen = (props) => {
  return (
    <div>
      <p className="section-title">Promjena podataka</p>
      <p className="article-content">Na ovoj stranici možete izmijeniti Vaše osobne podatke.</p>
      <UpdateProfile {...props}/>
    </div>
  )
};

export default UpdateProfileScreen;