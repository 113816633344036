import {Component} from "react";
import API from "../../API";
import React from "react";
import { Inquiry } from "./Inquiry";

export class InquiryScreen extends Component {
  constructor() {
    super();

    this.state = {
      areas: [],
      cities: [],
      schemas: [],
    }
  }

  async componentDidMount() {
    const cities = await this.getCities();
    const schemas = await this.getSchemas();

    this.setState({
      cities,
      schemas,
    });
  }

  async getCities() {
    const response = await API.get('wp-json/eupit/v1/city');

    return response.data;
  }

  async getSchemas() {
    const response = await API.get('wp-json/eupit/v1/schema');

    return response.data;
  }

  handleAcreditationShemaChange = async (selectedSchema) => {
    const response = await API.get(`wp-json/eupit/v1/schema/${selectedSchema.id}/area`);

    this.setState({
      areas: response.data,
    });
  };

  render() {
    const { areas, cities, schemas } = this.state;

    return (
      <div className="eupit-container">
        <aside className="eupit-container-sidebar">
          <p className="article-content">HAA je omogućila podnošenje upita za akreditaciju ispunjavanjem e-obrasca. Upit je iskaz interesa za početnom akreditacijom koji podnosi organizacija ili iskaz interesa za ponovnom akreditacijom, koji podnosi akreditirano tijelo. Nakon što pošaljete ispunjen obrazac, upisani podaci dostupni su u pdf obliku.
            Potvrdu o zaprimanju upita dostavit ćemo na mail adresu. U roku od tjedan dana zaprimit ćete odgovor na upit.</p>
          <p className="article-content"><a href="/wp-content/uploads/upit/doc/upute_podnosenje_e_upit.doc" target="_blank">Upute za popunjavanje e-Upita</a></p>
        </aside>
        <Inquiry
          {...this.props}
          areas={areas}
          cities={cities}
          handleAcreditationSchemaChange={this.handleAcreditationShemaChange}
          schemas={schemas}
        />
      </div>
    );
  }
}