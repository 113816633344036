import React, {Component} from "react";
import {CitySelector} from "../../components/CitySelector";

export class RegisterForm extends Component {
  handleDialCodeChange = (e, handleChange) => {
    const value = e.target.value;

    if (value.length <= 5 && (!isNaN(value) || value === '')) {
      handleChange(e);
    }
  };

  handlePhoneNumberChange = (e, handleChange) => {
    const value = e.target.value;

    if (value.length <= 12 && (!isNaN(value) || value === '')) {
      handleChange(e);
    }
  };

  render() {
    const {
      cities,
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      touched,
      values,
    } = this.props;

    return (
      <form className="register-form" onSubmit={handleSubmit}>
        <div className="login-form-elements">
          <p className="form-element-label">Ime</p>
          <div className="form-element">
            <input
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              type="text"
              name="name"
              placeholder="Ime"
            />
            {errors.name && touched.name && <div className="form-element-feedback">{errors.name}</div>}
          </div>
          <p className="form-element-label">Prezime</p>
          <div className="form-element">
            <input
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.surname}
              type="text"
              name="surname"
              placeholder="Prezime"
            />
            {errors.surname && touched.surname && <div className="form-element-feedback">{errors.surname}</div>}
          </div>
          <p className="form-element-label">E-mail adresa</p>
          <div className="form-element">
            <input
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              type="email"
              name="email"
              placeholder="E-mail adresa"
            />
            {errors.email && touched.email && <div className="form-element-feedback">{errors.email}</div>}
          </div>
          <p className="form-element-label">Adresa</p>
          <div className="form-element">
            <input
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.address}
              type="text"
              name="address"
              placeholder="Adresa"
            />
            {errors.address && touched.address && <div className="form-element-feedback">{errors.address}</div>}
          </div>
          <p className="form-element-label">Mjesto</p>
          <div className="form-element">
            <input
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.city}
              type="text"
              name='city'
              placeholder="Grad"
            />
            {errors.city && touched.city && <div className="form-element-feedback">{errors.city}</div>}
          </div>
          <p className="form-element-label">Poštanski broj</p>
          <div className="form-element">
            <input
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.postal}
              type="text"
              name="postal"
              placeholder="Poštanski broj"
            />
            {errors.postal && touched.postal && <div className="form-element-feedback">{errors.postal}</div>}
          </div>
          <p className="form-element-label">Telefon</p>
          <div className="form-element form-element-phone-group">
            <input
              onChange={(e) => this.handleDialCodeChange(e, handleChange)}
              onBlur={handleBlur}
              value={values.countryDialCode}
              type="text"
              name="countryDialCode"
              placeholder="385"
            />
            {errors.countryDialCode && touched.countryDialCode && <div className="form-element-feedback">{errors.countryDialCode}</div>}
            <input
              onChange={(e) => this.handleDialCodeChange(e, handleChange)}
              onBlur={handleBlur}
              value={values.cityDialCode}
              type="text"
              name="cityDialCode"
              placeholder="01"
            />
            {errors.cityDialCode && touched.cityDialCode && <div className="form-element-feedback">{errors.cityDialCode}</div>}
            <input
              onChange={(e) => this.handlePhoneNumberChange(e, handleChange)}
              onBlur={handleBlur}
              value={values.phoneNumber}
              type="text"
              name="phoneNumber"
              placeholder="1234567"
            />
            {errors.phoneNumber && touched.phoneNumber && <div className="form-element-feedback">{errors.phoneNumber}</div>}
          </div>
          <p className="form-element-label">Korisničko ime</p>
          <div className="form-element">
            <input
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.username}
              maxLength={15}
              type="text"
              name="username"
              placeholder="Korisničko ime"
            />
            {errors.username && touched.username && <div className="form-element-feedback">{errors.username}</div>}
          </div>
          <p className="form-element-label">Korisnička grupa:</p>
          <label>
            <input
              onChange={handleChange}
              onBlur={handleBlur}
              type="radio"
              value="assessor"
              name="userGroup"
              checked={values.userGroup === 'assessor'}
            />
            Ocjenitelj
          </label>
          <label>
            <input
              onChange={handleChange}
              onBlur={handleBlur}
              type="radio"
              value="working-groups"
              name="userGroup"
              checked={values.userGroup === 'working-groups'}
            />
            Radne skupine
          </label>
          <label>
            <input
                onChange={handleChange}
                onBlur={handleBlur}
                type="radio"
                value="presiding-officer"
                name="userGroup"
                checked={values.userGroup === 'presiding-officer'}
            />
            Voditelj Postupka
          </label>
          <button className="btn-submit" type="submit">Registriraj se</button>
        </div>
      </form>
    );
  }
}
