import React, { Component } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';

import AcreditationsRegister from "./screens/AcreditationsRegister";
import AddressBook from "./screens/AddressBook";
import Documents from "./screens/Documents";
import Login from "./screens/Login";
import News from "./screens/News";
import UpdatePassword from "./screens/UpdatePassword";
import UpdateProfile from "./screens/UpdateProfile";

import Sidebar from "./components/Sidebar/Sidebar";
import API from "./API";
import {RegisterScreen} from "./screens/registration/RegisterScreen";

class App extends Component {
  constructor() {
    super();

    this.state = {
      user: {
        firstName: '',
        lastName: '',
        email: '',
        address: '',
        location: '',
        zip: '',
        phoneNumber: '',
        allowDataSharing: '',
      },
      token: window.localStorage.getItem('token'),
    };
  }

  setUser = (user) => {
    this.setState({
      user
    });
  };

  resetUser = () => {
    this.setState({
      user: {
        firstName: '',
        lastName: '',
        address: '',
        location: '',
        zip: '',
        phoneNumber: '',
        allowDataSharing: '',
      },
      token: '',
    });
  };

  setToken = (token) => {
    this.setState({
      token
    });
  };

  componentDidMount = async () => {
    const token = window.localStorage.getItem('token');

    if (!token) {
      return;
    }

    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'X-Request-Source': 'admin',
      },
    };

    const user = await API.get('wp-json/wp/v2/users/me', config);

    this.setState({
      user: {
        id: user.data.id,
        firstName: user.data.firstName,
        lastName: user.data.lastName,
        email: user.data.user_email,
        address: user.data.meta.address,
        location: user.data.meta.city,
        phoneNumber: user.data.meta.phoneNumber,
        roleList: user.data.roleList,
        zip: user.data.meta.zipcode,
        allowDataSharing: user.data.meta.isPublicProfile,
      },
      token
    });
  };

  render() {
    const { token, user } = this.state;

    return (
      <div>
        <HashRouter>
          <div className="container">
            {token && <Sidebar resetUser={this.resetUser}/>}
            <Switch>
              <Redirect exact from="/" to="/news"/>
              {token && <Redirect from="/login" to="/news"/>}
              <Route exact path="/login" render={() => <Login setUser={this.setUser} setToken={this.setToken} />} />
              {!token && <Redirect to="/login"/>}
              <Route exact path="/news" render={() => <News/>} />
              <Route exact path="/acreditations" render={() => <AcreditationsRegister/>} />
              <Route exact path="/documents" render={() => <Documents/>} />
              <Route exact path="/address-book" render={() => <AddressBook/>} />
              <Route exact path="/update-password" render={() => <UpdatePassword id={user.id}/>} />
              <Route exact path="/update-profile" render={() => <UpdateProfile {...user} setUser={this.setUser} />} />
              <Route exact path="/logout" render={() => {
                window.localStorage.removeItem('token');
                window.localStorage.removeItem('authorizedUser');
                this.resetUser();
                window.location = '/'
              }} />
            </Switch>
          </div>
        </HashRouter>
      </div>
    );
  };
}

export default App;
